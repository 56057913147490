.outer-box {
  padding: 10px;
}

.button-group {
  margin-top: 40px;
}

i {
  color: rgb(21, 108, 167);
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.slider-cell {
  width: 400px;
  height: 40px;
}

.centered {
  text-align: center;
  vertical-align: middle;
}

.align-left {
  text-align: left;
}

select.fit-contents {
  height: 25px;
  width: fit-content;
}

.margin-right {
  margin-right: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.modal-input {
  width: 100%;
}

.template {
  background-color: whitesmoke;
}

.survey-box {
  min-height: 500px;
}

.uncertain {
  padding: 0.1rem;
  color: grey;
  font-size: 0.7rem;
  background: #cacaca;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  width: 5rem;
  margin: 0 auto 0 auto;
}

.editTemplateBox {
  border: gray 1px solid;
  padding: 10px;
}

.criterion-title {
  color: #0000ee;
}
